import { createContext, useContext, useState } from 'react'
import { ArrowTopRightOnSquareIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Helmet } from "react-helmet-async"
import { Link, Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Formik, Form, Field, useField, useFormikContext  } from "formik"
import { BrandStatus, BrandType, TrademarkClass, TrademarkFamily, useTrademarks } from "./TrademarksProvider"
import { useTranslation } from "react-i18next"
import _ from 'lodash'

import { AddAgentMask } from '../agents/AddAgent'
import { trademarkFamilyUrl, trademarkUrl } from './utils'
import { DatePicker } from "../components/input/DatePicker"
import { brandCountries, trade_mark } from "../data"
import { TmImage, TrademarkBreadcrumbs } from "./TrademarkPortfolio"
import { Trademark as TrademarkProps } from "./TrademarksProvider"
import { IconEdit, IconSpinner } from "../components/icons"
import Modal from "../components/Modal"
import { TFunction } from "i18next"
import TrademarkComments from './TrademarkComments'
import { useRoles } from '../user/Auth'
import TrademarkClassesContent from './TrademarkClasses'
//import {TrademarkDemonstrationOfUse} from './TrademarkDemonstration'
import { Fragment } from 'react'
import TrademarkTasks from './TrademarkTasks'
import { Agent, nameOf } from '../agents/utils'
import Trinary from '../components/input/Trinary'
import { LinkedTabCard } from '../components/TabCard'
import { FocusedBrowser } from '../documents/FocusedBrowser'
import { createChangedTrademarkState } from '../components/update'
import { TrademarkChanged } from '../components/update/TrademarkChanged'
import { emptyStringAsUndefined, trimStringNonEmpty } from '../utils/strings'
import DeleteButton from '../components/DeleteButton'
import { useAgents } from '../agents/AgentsProvider'
import { useTrademarkFamily } from './TrademarkFamily'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { downloadTrademark, formats, parseTrademarkRegistrationNumber } from '../import/trademarks'
import { count } from 'console'

export default function Trademark() {
    const {t} = useTranslation()

    const {hasDocuments} = useRoles()
    const {reference} = useParams()
    const { trademarkByReference, trademarkById, trademarkFamilyById, trademarkClassesByTrademarkId, ownersByTrademarkId, contactsByTrademarkId } = useTrademarks()

    const trademark = trademarkByReference[reference]

    if (trademark === undefined) return null

    const family = trademarkFamilyById[trademark.familyId]

    if (family === undefined) return null

    if (trademark.priorityId) {
        trademark['basis'] = trademarkById[trademark.priorityId]
    }
    trademark['owner'] = ownersByTrademarkId[trademark.trademarkId]?.[0]
    trademark['contact'] = contactsByTrademarkId[trademark.trademarkId]?.[0]

    trademark['trademarkClasses'] = trademarkClassesByTrademarkId[trademark.trademarkId]

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{reference} | {t('trademarks')} | Patent Cockpit</title>
        </Helmet>
        <div className="portfolio-menu">
            <h2 className="flex flex-row gap-1 md:gap-4 whitespace-nowrap shrink min-w-0 grow pr-4">
                <TrademarkBreadcrumbs {...{ family, trademark }} />
            </h2>
        </div>
        <div className="main-content bg-pcx-100 pt-0">
            <div className="w-full md:w-fit">
                <div className="lg:min-w-5xl xl:min-w-6xl">
                    <TrademarkProvider {...{ family, trademark }}>
                        <LinkedTabCard links={[
                            { to: '.', label: t('overview') },
                            { to: 'classes', label: t('trademark-classes') },
                            hasDocuments && { to: 'documents', label: t('documents') },
                        ]} />
                    </TrademarkProvider>
                </div>
            </div>
        </div>
        <Outlet />
    </>
}

const TrademarkContext = createContext({
    trademark: null as TrademarkProps | null,
    family: null as TrademarkFamily | null,
})

function TrademarkProvider({trademark, family, children}: {trademark: TrademarkProps, family: TrademarkFamily, children: React.ReactNode}) {
    return <TrademarkContext.Provider value={{trademark, family}}>{children}</TrademarkContext.Provider>
}

function useTrademark() {
    return useContext(TrademarkContext)
}

export function TrademarkOverview() {
    const { hasTasks } = useRoles()
    const { trademark, family } = useTrademark()

    if(!trademark || !family) return null

    return <>
        <div className="grid grid-cols-1 lg:grid-cols-[minmax(32rem,48rem)_400px] xl:grid-cols-[minmax(32rem,48rem)_480px] grid-cols-auto gap-2 grid-flow-dense divide-pcx-200 max-lg:divide-y-2 lg:divide-x-2">
            <div>
                <TrademarkDetails {...{ trademark, family }} />
            </div>
            <div className='space-y-5 max-lg:pt-2 lg:pl-2'>
                <TrademarkComments {...{ trademarkId: trademark.trademarkId, familyId: trademark.familyId }} />
                {hasTasks && <TrademarkTasks {...{ trademarkId: trademark.trademarkId }} />}
            </div>
        </div>
        <Outlet />
    </>
}

export function TrademarkClasses() {
    const { trademark } = useTrademark()

    if (!trademark) return null

    return <>
        <div className='max-w-3xl'>
            <TrademarkClassesContent {...{ trademarkId: trademark.trademarkId }} />
        </div>
        <Outlet />
    </>
}

export function TrademarkDocuments() {
    const { trademark, family } = useTrademark()

    if (!trademark) return null

    return <FocusedBrowser {...{entity: trade_mark, internalReference: trademark.reference, parent: family.reference, parentName: family.name}} />
}



export interface RichTrademark extends TrademarkProps {
    basis?: TrademarkProps;
    owner?: string;
    contact?: { firstName: string, lastName: string };
}
function TrademarkDetails({trademark, family}: {trademark: RichTrademark, family: TrademarkFamily}) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {isEditUser} = useRoles()
    const {deleteTrademark} = useTrademarks()

    return <>
        <div className="flex flex-col sm:flex-row gap-4 pb-2 border-b-2 border-pcx-200">
            <TmImage {...{family}} />
            <div className='space-y-2 grow'>
                <div className="flex flex-row gap-1">
                    <h2 className="grow">{trademark.reference}: {family.name}</h2>
                    {isEditUser && <>
                        <Link className="btn-primary shrink-0 p-px w-5 h-5" to="edit"><IconEdit /></Link>
                        <DeleteButton className="btn-warn shrink-0 p-px w-5 h-5" deleteAction={() => deleteTrademark(trademark).then(() => navigate(trademarkFamilyUrl(family)))} />
                    </>}
                </div>
                {trademark.words && <p className="max-w-prose font-medium">{trademark.words}</p>}
                <p className="max-w-prose">{family.description}</p>
                {trademark.url &&
                    <a href={trademark.url}
                        target="_blank" rel="noreferrer"
                        className="underline mt-1 flex flex-row gap-1 items-center text-pcx-600"
                    >
                        <ArrowTopRightOnSquareIcon className='h-5 w-5' /> {t('external-link')}
                    </a>}
            </div>
        </div>
        <h4 className="mt-4 mb-4">{t('bibliographic-data')}</h4>
        <div className="grid grid-cols-[160px_1fr] gap-x-4 gap-y-2">
            {fields.map(({ name, render }) => {
                const output = render(trademark, t)
                if (!output) return null
                return <Fragment key={name}>
                    <div className="text-right text-gray-500">{t(name)}</div>
                    <div>{output}</div>
                </Fragment>
            })}
        </div>
        <TrademarkChanged />
    </>
}

const fields = [
    {
        name: 'externalReference',
        render: (trademark: TrademarkProps, t: TFunction) => trademark.externalReference,
    },
    {
        name: 'trademark-classes',
        render: ({trademarkClasses}: {trademarkClasses?: TrademarkClass[]}, t: TFunction) => trademarkClasses && 
            // must be a instead of a Link for jumping to the id
            // TODO: use new link with new design
            <Link to="classes" className='underline text-pcx-600'>{trademarkClasses.map(({number}) => number).join(', ')}</Link>,
    },
    {
        name: 'brandType', 
        render: (trademark: TrademarkProps, t: TFunction) => trademark.brandType && trademark.brandType.split(',').map(t).join(', '),
    },
    {
        name: 'application',
        render: (trademark: TrademarkProps, t: TFunction) => trademark.applicationNumber && 
            (trademark.applicationDate ? `${trademark.applicationNumber} (${trademark.applicationDate})` : trademark.applicationNumber),
    },
    {
        name: 'registration',
        render: (trademark: TrademarkProps, t: TFunction) => trademark.registrationNumber && 
            (trademark.registrationDate ? `${trademark.registrationNumber} (${trademark.registrationDate})` : trademark.registrationNumber),
    },
    {
        name: 'next-usage-proof',
        //render: (trademark: TrademarkProps, t: TFunction) => trademark.usageProofDate && <a href="#demonstration-of-use" className='underline text-pcx-600'>{trademark.usageProofDate}</a>,
        render: (trademark: TrademarkProps, t: TFunction) => trademark.usageProofDate ?? '',
    },
    {
        name: 'renewalDate',
        render: (trademark: TrademarkProps, t: TFunction) => trademark.renewalDate,
    },
    {
        name: 'status',
        render: (trademark: TrademarkProps, t: TFunction) => <>
            {t(trademark.status)} {
                trademark.status === 'registered' ? `(${t('granted')})` :
                trademark.status === 'applied' ? `(${t('pending')})` : 
                null}
        </>,
    },
    {
        name: 'country',
        render: (trademark: TrademarkProps, t: TFunction) => trademark.countryCode,
    },
    {
        name: 'priority',
        render: (trademark: TrademarkProps & {basis?: TrademarkProps}, t: TFunction) => <>{trademark.basis && <Link className='text-pcx-600 underline' to={trademarkUrl(trademark.basis)}>{trademark.basis.reference}</Link>}</>,
    },
    {
        name: 'fromWipo',
        render: (trademark: TrademarkProps, t: TFunction) => trademark.fromWipo !== undefined && (trademark.fromWipo ? t('yes') : t('no')),
    },
    {
        name: 'owner',
        render: (trademark: TrademarkProps & {owner?: Agent}, t: TFunction) => <>{trademark.owner ? nameOf(trademark.owner) : null}</>,
    },
    {
        name: 'contact-person',
        render: (trademark: TrademarkProps & {contact?: Agent}, t: TFunction) => <>{trademark.contact ? nameOf(trademark.contact) : null}</>,
    },
]

type TrademarkEditProps = TrademarkProps & { owner?: number | string, contact?: number | string}

function asNumber(value: string | number) {
    return typeof value === 'string' ? parseInt(value) : value
}

export function PostTrademark() {
    const {t} = useTranslation()
    const {hasDocuments, hasEuipoImport} = useRoles()

    const {reference} = useParams()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const { 
        trademarkById, trademarkByReference, trademarksByFamilyId, postTrademark,
        postTrademarkAgentLink, deleteTrademarkAgentLink, ownersByTrademarkId, contactsByTrademarkId,
        postTrademarkClass,
        trademarks,
     } = useTrademarks()
    const family = useTrademarkFamily()
    const { agents, postAgent } = useAgents()
    const location = useLocation()

    const persons = _(agents)
        .filter(agent => agent.agentType === 'person')
        .map(agent => ({...agent, name: `${agent.firstName} ${agent.lastName}`}))
        .sortBy(a => a.name.toLocaleLowerCase())
        .value()
    const companies = _(agents)
        .filter(agent => agent.agentType === 'company')
        .sortBy(a => a.name.toLocaleLowerCase())
        .value()

    const trademark = trademarkByReference[reference]
    const isAdding = !trademark

    const familyId = family?.familyId

    const copyId = parseInt(searchParams.get('copyFrom'))
    const isCopy = !isNaN(copyId)

    const isImport = location.state?.prefill !== undefined

    //console.log({isImport, prefill: location.state?.prefill})

    // TODO: set owner & contact
    const initialValues: TrademarkEditProps = trademark ?? (
        isCopy 
        ? {...trademarkById[copyId] , reference: '', trademarkId: undefined}
        : isImport
        ? location.state?.prefill
        : {
            reference: "",
            familyId,
            countryCode: "IR",
            brandType: "Word" as BrandType,
            status: "planned",
        })
    initialValues['owner'] = ownersByTrademarkId[isCopy ? copyId : initialValues.trademarkId]?.[0]?.agentId
    initialValues['contact'] = contactsByTrademarkId[isCopy ? copyId : initialValues.trademarkId]?.[0]?.agentId

    const tmClasses = location.state?.prefill?.classes ?? []

    const possiblePriorityIds = (trademarksByFamilyId[familyId] ?? [])
        .map((trademark: TrademarkProps)  => trademark.trademarkId)
        .filter(id => id !== trademark?.trademarkId && trademarkById[id]?.reference !== undefined)

    //console.log({trademark})
    //console.log({initialValues})

    const otherReferences = new Set(_(trademarks).filter(t => t.trademarkId !== trademark?.trademarkId).map(t => t.reference).value())

    async function submitAgentLinks(trademarkId: number, values: TrademarkEditProps, type: 'owner' | 'contact') {
        if (isCopy && values[type]) {
            await postTrademarkAgentLink({agentId: asNumber(values[type]), trademarkId, type})
        } else if (values[type] !== initialValues[type] && values[type]) {
            if (initialValues[type]) {
                await deleteTrademarkAgentLink({agentId: asNumber(initialValues[type]), trademarkId, type})
            }
            await postTrademarkAgentLink({agentId: asNumber(values[type]), trademarkId, type})
        }
    }

    async function onSubmit(values: TrademarkEditProps) {
        // @ts-ignore
        const priorityId = parseInt(values.priorityId)
        const tm = {
            ...values, 
            priorityId: isNaN(priorityId) ? undefined : priorityId,
            ..._.fromPairs(['reference', 'externalReference', 'words', 'applicationNumber', 'registrationNumber', 'url']
                .map(f => [f, trimStringNonEmpty(values[f])]))
        }

        const newTrademark = await postTrademark(tm)
        // console.log({values, newTrademark})
        await submitAgentLinks(newTrademark.trademarkId, values, 'owner')
        await submitAgentLinks(newTrademark.trademarkId, values, 'contact')

        const classesToAdd = tmClasses.length > 0 ? tmClasses : (values['classes'] ?? [])
        if (isAdding && classesToAdd.length > 0) {
            const trademarkId = newTrademark.trademarkId
            await Promise.all(classesToAdd.map(({number, description}) => postTrademarkClass({trademarkId, number, description})))
        }

        const state = createChangedTrademarkState({ hasDocuments, oldTrademark: trademark, newTrademark })
        return navigate(trademarkUrl(newTrademark), { state })
    }

    function validate(values: TrademarkEditProps) {
        const errors = {}
        if (values.reference && otherReferences.has(values.reference)) {
            errors['reference'] = t('reference-used')
        }
        //console.log({errors, otherReferences})
        return errors
    }

    return (
        <Modal>
            <Formik 
                initialValues={initialValues} 
                onSubmit={onSubmit}
                validate={validate}
                enableReinitialize
            >{({touched, errors}) => 
                <Form>
                    <div className="flex flex-col gap-4 p-4">
                        <h2>{isAdding ? t('add-trademark', {name: family.reference}) : t('edit-trademark', { name: initialValues.reference })}</h2>
                        {hasEuipoImport && <LoadButton />}
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <label className="block">
                                <div>
                                    <span className='label inline'>{t('reference')}</span>
                                    {touched.reference && errors.reference && <span className='text-red-500 ml-2 max-sm:text-sm'>{errors.reference}</span>}
                                </div>
                                <Field name="reference" className="form-input mt-1 w-full" type="text" autoFocus required />
                            </label>
                            <label className="block">
                                <div className="label">{t('externalReference')}</div>
                                <Field name="externalReference" className="form-input mt-1 w-full" type="text" />
                            </label>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className='flex flex-row gap-4 max-w-[20rem]'>
                                <label>
                                    <div className="label">{t('country')}</div>
                                    <Field name="countryCode" className="form-select mt-1" as="select">
                                        {brandCountries.map(value => <option key={value} value={value}>{value}</option>)}
                                    </Field>
                                </label>
                                <label className="block">
                                    <div className="label mb-1 block">{t('status')}</div>
                                    <StatusSelect />
                                </label>
                            </div>
                            <div className='flex flex-row gap-4'>
                                <label>
                                    <div className="label">{t('basis')} / {t('priority')}</div>
                                    <Field name="priorityId" className="form-select mt-1" as="select">
                                        <option>{t('none')}</option>
                                        {possiblePriorityIds.map(id => <option key={id} value={id}>{trademarkById[id].reference}</option>)}
                                    </Field>
                                </label>
                                <label>
                                    <div className="label">{t('fromWipo')}</div>
                                    <Trinary name="fromWipo" />
                                </label>
                            </div>
                        </div>
                        <div className='flex flex-row gap-4'>
                            <label className="block min-w-[8rem]">
                                <div className="label mb-1">{t('brandType')}</div>
                                <BrandTypeSelect name="brandType" />
                            </label>
                            <label className="block grow">
                                <div className="label mb-1">{t('words')}</div>
                                <Field name="words" type="text" className="form-input w-full" />
                            </label>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {['applicationNumber', 'applicationDate', 'registrationNumber', 'registrationDate'].map(field =>
                                <label className="block" key={field}>
                                    <div className="label">{t(field)}</div>
                                    <Field 
                                        name={field} className="form-input mt-1 py-2 w-full" 
                                        {...(field.endsWith('Date') 
                                            ? {as: DatePicker}
                                            : {type: "text"} 
                                        )} />
                                </label>)}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label className="block">
                                <div className="label">{t('next-usage-proof')}</div>
                                <Field name="usageProofDate" className="form-input mt-1 py-2 w-full" as={DatePicker} />
                            </label>
                            <label className="block">
                                <div className="label">{t('renewalDate')}</div>
                                <Field name="renewalDate" className="form-input mt-1 py-2 w-full" as={DatePicker} />
                            </label>
                        </div>
                        <label className='block md:col-span-2'>
                            <div className="label">{t('external-link')}</div>
                                <Field name="url" className="form-input mt-1 py-2 w-full" type="url" />
                        </label>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className='w-full'>
                                <div className="mb-1 flex flex-row justify-between">
                                    <label htmlFor='owner' className='label'>{t('owner')}</label>
                                    <AddAgentButton name="owner" title={t('add-owner')} withCompanies />
                                </div>
                                <Field id='owner' name="owner" className="form-select w-full max-w-[20rem]" as="select" >
                                    <option value={undefined}>{t('none')}</option>
                                    <optgroup label={t('companies')}>
                                        {companies.map(a => <option key={a.agentId} value={a.agentId}>{a.name}</option>)}
                                    </optgroup>
                                    <optgroup label={t('persons')}>
                                        {persons.map(a => <option key={a.agentId} value={a.agentId}>{a.name}</option>)}
                                    </optgroup>
                                </Field>
                            </div>
                            <div className='w-full'>
                                <div className="mb-1 flex flex-row justify-between">
                                    <label htmlFor='contact' className='label'>{t('contact-person')}</label>
                                    <AddAgentButton name="contact" title={t('add-contact')} />
                                </div>
                                <Field id="contact" name="contact" className="form-select w-full max-w-[20rem]" as="select" >
                                    <option value={undefined}>{t('none')}</option>
                                    {persons.map(a => <option key={a.agentId} value={a.agentId}>{a.name}</option>)}
                                </Field>
                            </div>
                        </div>
                    </div>
                    <div className="bg-pcx-200 flex flex-row-reverse gap-4 p-4">
                        <input type="submit" className="btn-primary" value={t('save')} />
                        {/* @ts-ignore */}
                        <Link to={-1} className="btn-secondary">{t('cancel')}</Link>
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}

function LoadButton() {
    const {t} = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(undefined)
    const {agents, postAgent} = useAgents()
    const agentByName = _(agents).map(a => ({...a, name: nameOf(a).toLocaleLowerCase()})).keyBy('name').value()

    const { values, setValues } = useFormikContext<TrademarkEditProps>()

    async function handleLoad() {
        // console.log('loading')
        setIsLoading(true)
        setError(undefined)

        const applicationNumber = emptyStringAsUndefined(values.applicationNumber)
        if (!applicationNumber) {
            setError(`${t('required')}: ${t('applicationNumber')}`)
            setIsLoading(false)
            return
        }

        let number = applicationNumber
        // console.log({number, vorher: '', countryCode: values.countryCode})
        if (!/^[A-Za-z]{2}/.test(number)) {
            if (values.countryCode === 'US') {
                number = `US${number}`
            } else if (values.countryCode === 'EU') {
                number = `EM${number}`
            }
        }
        const parserResult = parseTrademarkRegistrationNumber(number)
        // console.log({number, parserResult})
        try {
            if (parserResult.format === 'unknown') {
                throw new Error(_(formats).map(f => `${f.office}: ${f.format} ${f.comment}`).join('; '))
            }
            const result = await downloadTrademark(parserResult)

            if (result.owner !== undefined && values.owner === undefined) {
                const owner = result['owner'] as string
                const agent = agentByName[owner.toLocaleLowerCase()]
                if (agent) {
                    result['owner'] = agent.agentId
                } else {
                    const ownerAgent = await postAgent({name: owner, agentType: 'company'})
                    result['owner'] = ownerAgent.agentId
                }
            }

            // TODO: check owner
            const updates = _(tmKeys)
                .map(key => {
                    const value = values[key]
                    if (value === undefined ||
                        value === '' ||
                        // if country is IR it has probably not been changed so far
                        (key === 'countryCode' && value === 'IR') || 
                        // if status is planned it has probably not been changed so far
                        (key === 'status' && value === 'planned')) {
                        return [key, result[key]]
                    } else {
                        return [key]
                    }
                }) 
                .filter(([key, value]) => value !== undefined)
                .fromPairs()
                .value()
            setValues({...values, ...updates})
        } catch (error) {
            console.error(error)
            setError(error.message)
        }
        
        setIsLoading(false)
    }

    return <div className='w-full'>
        <div className="bg-pcx-200 rounded text-center w-full py-0.5">
            {t("fill-the-form-or")}
            <button className="btn-tertiary inline-flex gap-1 items-center" type="button" onClick={handleLoad}>
                {t('load-from-po')} (EUIPO/USPTO) {isLoading ? <IconSpinner className='h-5 w-5 animate-spin' /> : <ArrowDownTrayIcon className="h-5 w-5" />}
            </button>
        </div>
        {error && <div className='w-full mt-1 text-sm flex flex-row items-center gap-3 bg-red-50 rounded-md px-3 py-1'>
            <ExclamationTriangleIcon className='size-4 text-warn-500 shrink-0 mt-0.5' />
            <div className='text-warn-700 grow'>{error}</div>
            <button type="button" onClick={() => setError(undefined)}><XMarkIcon className='text-warn-900 hover:text-warn-800 size-4'/></button>
        </div>}
    </div>
}

const tmKeys = [
    'countryCode',
    'applicationNumber',
    'applicationDate',
    'registrationNumber',
    'registrationDate',
    'brandType',
    'words',
    'status',
    'url',
    'renewalDate',
    'classes',
    'owner',
]

function AddAgentButton({name, title, withCompanies = false}: {name: string, title: string, withCompanies?: boolean}) {
    const [showMask, setShowMask] = useState(false)

    const [, , helpers] = useField(name)
    const { setValue } = helpers

    return <>
        <button type="button" className="btn-secondary text-xs" onClick={() => setShowMask(true)}>{title}</button>
        {showMask && <AddAgentMask {...{
            title,
            setShowMask,
            withCompanies,
            update: (v) => setValue(v)
        }} />}
    </>
}

const statusValues = [
    'planned',
    'applied',
    'registered',
    'stopped',
] satisfies BrandStatus[]

const notes = {
    applied: 'pending',
    registered: 'granted',
}

function renderStatusValue(status: BrandStatus, t: TFunction) {
    const note = notes[status]
    return `${t(status)} ${note ? `(${t(note)})` : ''}`
}

function StatusSelect() {
    const {t} = useTranslation()
    return <Field name="status" as="select" className="form-select w-full truncate" >
        {statusValues.map((status, i) =>
            <option key={status} value={status}>{renderStatusValue(status, t)}</option>
        )}
    </Field>
}

// TODO: Just create combined instead of multi-select
const brandTypes = ['3D', 'Combined', 'Image', 'Position', 'Sound', 'Smell', 'Word']
function BrandTypeSelect({ name }: { name: string}) {
    const { t } = useTranslation()
    return <Field {...{ name }} className="form-select w-full" as="select">
        {_(brandTypes)
            .map(value => ({ value, name: t(value) }))
            .sortBy('name')
            .map(({ value, name }) =>
                <option key={value} value={value}>{name}</option>)
            .value()
        }
    </Field>
}