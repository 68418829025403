import { Link, Outlet, } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useTranslation, Trans } from "react-i18next"

import { IconXCircle } from "../components/icons";
import Logo from '../components/Logo'
import { useMessages } from "../Messages";
import clsx from "clsx";

export function MessagesDisplay() {
    const { t } = useTranslation()
    const { infoMessage, setInfoMessage, errorMessage, setErrorMessage } = useMessages()

    const hasErrorMessage = errorMessage !== undefined

    if (!hasErrorMessage && !infoMessage) return null

    const close = hasErrorMessage ? () => setErrorMessage(undefined) : () => setInfoMessage(undefined)

    return (
        <div className={clsx("p-2 w-full", hasErrorMessage ? "bg-red-100 " : "bg-pcx-200")}>
            <div className="flex flex-row items-baseline">
                <h3 className="font-semibold">{hasErrorMessage ? t('error') : 'Info'}: </h3>
                <div className="grow pl-2">{errorMessage ?? infoMessage}</div>
                <button onClick={close}><IconXCircle /></button>
            </div>
        </div>
    )
}


export function UnauthenticatedContent() {
    return (
        /* @ts-ignore */
        <HelmetProvider >
            {/* @ts-ignore */}
            <Helmet>
                <title>Patent Cockpit</title>
            </Helmet>
            <div className="bg-pcx-100">
                <div className="flex flex-col items-center background-gradient dark:bg-pcx-800 min-h-screen w-screen px-2">
                    <MessagesDisplay />
                    <div className="sm:pt-16 sm:pb-10 py-5">
                        <h1 className="dark:text-pcx-100">
                            <a href="https://patent-cockpit.com" target="_blank" rel="noreferrer">
                                <Logo className="h-12" />
                            </a>
                        </h1>
                    </div>
                    <div className="main-content-unauthenticated sm:w-fit h-fit rounded-lg shadow-xl shadow-pcx-500/20 p-4 sm:p-8">
                        <Outlet />
                    </div>
                    {/* TODO: add footer */}
                </div>
            </div>
        </HelmetProvider>
    )
}


export function NoTabMenu() {
    return (
        <div className="flex flex-col grow h-full relative sm:overflow-y-auto">
            <div className="flex flex-row tab-bar h-10">
                <div className="tab-active">
                    Portfolio
                </div>
                <div className="grow tab-filler" />
            </div>
            <div className="grow">
                <Outlet />
            </div>
        </div>
    )
}

export function NotFound() {
    const { t } = useTranslation()
    return (
        <div className="main-content">
            <h2>{t('page-not-found')}</h2>
            <Trans t={t} i18nKey="please-return" components={{homelink: <Link className="underline" to="/cockpit"></Link>}} />
        </div>
    )
}
